import { Store } from "artisn/types";
import { GetStaticProps } from "next";
import Head from "next/head";
import React from "react";
import { QueryClient } from "react-query";
import { dehydrate } from "react-query/hydration";

import TabsMenu from "components/global/TabsMenu/TabsMenu";
import Home from "components/home/Home/Home";
import CONSTANTS from "config/constants";
import { prefetchDefaultStore } from "utils/cache.utils";
import { prefetchCategoriesWithProducts } from "utils/cache.utils";
import { prefetchBanners, prefetchCategories } from "utils/cache.utils";
import { notify } from "utils/common.utils";

const { CATEGORIES_PER_REQUEST, PRODUCTS_PER_CATEGORY_REQUEST } = CONSTANTS.API;

interface Props {}

const HomePage = (props: Props) => {
  return (
    <>
      <Head>
        <title>Chef Choy | la mejor comida china a domicilio en Quito</title>
        <meta
          property="og:title"
          content="Chef Choy | la mejor comida china a domicilio en Quito"
        />
        <meta
          name="description"
          content="Aprovecha los mejores descuentos y promociones en Chef Choy, la mejor comida china a domicilio. Cupones exclusivos para nuestros clientes en Quito."
        />
        <meta
          property="og:description"
          content="Aprovecha los mejores descuentos y promociones en Chef Choy, la mejor comida china a domicilio. Cupones exclusivos para nuestros clientes en Quito."
        />
        <meta name="robots" content="index, follow" />
        <meta name="keywords" content="Comida china en Quito" />
      </Head>
      <h1 style={{ display: "none" }}>Comida china a domicilio en Quito</h1>
      <TabsMenu>
        <Home />
      </TabsMenu>
    </>
  );
};

export const getStaticProps: GetStaticProps = async context => {
  const queryClient = new QueryClient();

  try {
    // Banners
    const banners = prefetchBanners(queryClient, "home_banner");

    // Default store
    await prefetchDefaultStore(queryClient);
    const defaultStore = queryClient.getQueryData<Store>(["stores", "default"]);

    // Categories
    const categories = prefetchCategories(queryClient, defaultStore!.storeId);

    // Categories with products
    const categoriesWithProducts = prefetchCategoriesWithProducts(queryClient, {
      storeId: defaultStore!.storeId,
      productsByGroup: PRODUCTS_PER_CATEGORY_REQUEST,
      size: CATEGORIES_PER_REQUEST
    });

    await Promise.all([banners, categories, categoriesWithProducts]);
  } catch (e) {
    notify(e, "Prefetch banners, categories and categories with products");
    console.error(e.message);
  }

  return {
    props: {
      dehydratedState: JSON.parse(JSON.stringify(dehydrate(queryClient)))
    },
    revalidate: 60 * 30
  };
};

export default HomePage;
